/* eslint-disable no-underscore-dangle */
(function iife(App, $) {
  /** @type {Boolean} Is local debug active? */
  const localDebug = true;
  /** @type {Boolean} Is debug active? */
  const debug = App.hasDebug() && localDebug;
  /** @type {Function} Log helper */
  const { log } = App.components.Debugger(debug, 'Alert');

  /** @type {Object} Default position of the alert */
  const DEFAULT_POSITION = {
    x: 'right',
    y: 'top',
  };

  /**
   * Helper function to create buttons
   *
   * @param  {String} label   The label of the button
   * @param  {Array}  classes Additional classes to add
   * @return {Object}         The created jQuery element
   */
  function createBtn(label, classes) {
    const $container = $('<span/>', { class: 'alert__btn' });
    const $btn = $('<button/>', { class: 'btn btn--small' });

    $btn.addClass(classes.join(' ')).append(`<span class="btn__inner">${label}</span>`);

    return $container.append($btn);
  }

  /**
   * Alert constructor
   *
   * @return {Object} The created instance
   */
  function Alert() {
    if (!(this instanceof Alert)) return new Alert();
    log('constructor');

    this.defaults = {
      $container: $('#js-alert-container'),
      content: '<p>Default content</p>',
      classes: [],
      duration: 3,
      position: {
        x: 'right',
        y: 'top',
      },
    };

    this.cache = {
      errors: [],
      warning: [],
      success: [],
      message: [],
      top: {
        left: {},
        right: {},
      },
      bottom: {
        left: {},
        right: {},
      },
    };

    // Create the main template
    this.$alert = $('<div/>', { class: 'alert' });
    this.$content = $('<div/>', { class: 'alert__content' });

    // Add the close icon
    this.$content
      .append(
        [
          '<span class="alert__close">',
          '<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" width="12" height="12" viewBox="0 0 12 12" xml:space="preserve"><polygon points="12 1.1 10.9 0 6 4.9 1.1 0 0 1.1 4.9 6 0 10.9 1.1 12 6 7.1 10.9 12 12 10.9 7.1 6 "/></svg>',
          '</span>',
        ].join('')
      )
      .appendTo(this.$alert);

    TweenLite.set(this.$alert, { y: '-101%' });

    return this;
  }

  /**
   * Set the position of the alert
   * @param {String} position.x Position on the x axis: left|right
   * @param {String} position.y Position on the y axis: top|bottom
   */
  Alert.prototype.setPosition = function setPosition(position) {
    log('setPosition', position);

    const x =
      position && position.x && typeof position.x === 'string' ? position.x : DEFAULT_POSITION.x;
    const y =
      position && position.y && typeof position.y === 'string' ? position.y : DEFAULT_POSITION.y;

    this.defaults.position = {
      x,
      y,
    };
  };

  /**
   * Opens a new alert block
   *
   * @param  {Object} opts The options for the current alert block
   * @return {Object}      The current instance's object
   */
  Alert.prototype.open = function open(opts) {
    const options = $.extend(true, {}, this.defaults, opts);

    const $clone = this.$alert.clone();

    $clone.find('.alert__content').append(options.content);
    // Add position class
    $.each(options.position, function (key, position) {
      options.classes.push(`alert--${position}`);
    });

    const id = App.helpers.uniqId('alert');
    $clone
      .attr('id', id)
      .data('options', options)
      .addClass(options.classes.join(' '))
      .appendTo(options.$container)
      .on(
        'click',
        function () {
          this.close($clone);
        }.bind(this)
      );

    const { y } = options.position;
    const { x } = options.position;
    const cachedItems = this.cache[y][x];

    const height = $clone.innerHeight();
    $.each(cachedItems, function (key) {
      TweenLite.to(cachedItems[key], 0.6, {
        y: y === 'top' ? `+=${height}` : `-=${height}`,
        ease: Expo.easeOut,
      });
    });
    this.cache[y][x][id] = $clone;

    TweenLite.set($clone, {
      y: options.position.y === 'top' ? '-101%' : '101%',
    });

    TweenLite.to($clone, 0.6, {
      y: '0%',
      ease: Expo.easeOut,
      onComplete: function () {
        // Close automatically the alert
        // if the duration is specified
        // to be more than 0
        if (options.duration > 0) {
          setTimeout(
            function () {
              this.close($clone);
            }.bind(this),
            options.duration * 1000
          );
        }
      }.bind(this),
    });

    return $clone;
  };

  /**
   * Close the current alert block
   *
   * @param  {Object} $clone The $clone to close
   * @return {Object}        The curent instance's object
   */
  Alert.prototype.close = function close($clone) {
    // Stop here if is already closing
    if ($clone.__isClosing) return this;

    // Set isClosing state
    $clone.__isClosing = true;

    $clone.css({
      pointerEvents: 'none',
    });

    const options = $clone.data('options');
    const id = $clone.attr('id');
    const { y } = options.position;
    const { x } = options.position;
    delete this.cache[y][x][id];

    // Close it
    TweenLite.to($clone, 0.6, {
      x: options.position.x === 'left' ? '-101%' : '101%',
      ease: Expo.easeOut,
      onComplete() {
        $clone.remove();
      },
    });
    return this;
  };

  /**
   * Alias to open a new message alert
   *
   * @param  {String}  content  The content of the message
   * @param  {Integer} duration The life duration of the alert
   * @return {Object}           The current instance's object
   */
  Alert.prototype.message = function message(content, duration) {
    const options = {
      content,
    };

    if (typeof duration !== 'undefined') options.duration = duration;

    const $clone = this.open(options);
    this.cache.message.push($clone);

    return this;
  };

  /**
   * Alias to open a new error alert
   *
   * @param  {String}  content  The content of the error
   * @param  {Integer} duration The life duration of the alert
   * @return {Object}           The current instance's object
   */
  Alert.prototype.error = function error(content, duration) {
    const options = {
      content,
      classes: ['alert--error'],
    };

    options.duration = typeof duration !== 'undefined' ? duration : 0;

    // Open the error and save it
    // in the `errors` array
    const $clone = this.open(options);
    this.cache.errors.push($clone);

    return this;
  };

  /**
   * Alias to open a new error alert
   *
   * @param  {String}  content  The content of the error
   * @param  {Integer} duration The life duration of the alert
   * @return {Object}           The current instance's object
   */
  Alert.prototype.warning = function warning(content, duration) {
    const options = {
      content,
      classes: ['alert--warning'],
    };

    options.duration = typeof duration !== 'undefined' ? duration : 0;

    // Open the error and save it
    // in the `errors` array
    const $clone = this.open(options);
    this.cache.warning.push($clone);

    return this;
  };

  /**
   * Close all error alerts
   * @return {Alert} The current instance
   */
  Alert.prototype.closeErrors = function closeErrors() {
    return this.closeAlerts('errors');
  };

  /**
   * Close all open alerts of a given type
   * @param  {String} type The type of alerts to close
   * @return {Alert}       The current instance
   */
  Alert.prototype.closeAlerts = function closeAlerts(type) {
    $.each(this.cache[type], function (index, $item) {
      $item.trigger('click');
    });

    return this;
  };

  /**
   * Alias to open a new error alert
   *
   * @param  {String}  content  The content of the error
   * @param  {Integer} duration The life duration of the alert
   * @return {Object}           The current instance's object
   */
  Alert.prototype.success = function success(content, duration) {
    const options = {
      content,
      classes: ['alert--success'],
    };

    options.duration = typeof duration !== 'undefined' ? duration : 0;

    // Open the error and save it
    // in the `errors` array
    const $clone = this.open(options);
    this.cache.success.push($clone);

    return this;
  };

  /**
   * Create a confirmation alert
   *
   * @param  {Object} options The options of the alert
   * @return {Boolean}        Return false to prevent default action
   */
  Alert.prototype._confirm = function _confirm(opts) {
    const options = $.extend(true, {}, this.defaults, opts);

    // Add the confirm class
    options.classes.push('alert--confirm');

    // Create our node structure
    const $clone = this.$alert.clone();
    const $btnContainer = $('<p/>', { class: 'alert__footer' });
    const $accept = createBtn(options.labelConfirm, ['btn--primary']);
    const $decline = createBtn(options.labelDecline, ['btn--secondary']);

    if (options.labelDecline !== undefined) {
      $btnContainer.append($decline);
    }
    $btnContainer.append($accept);

    // Add position class
    Object.keys(options.position).forEach(function (key) {
      const position = options.position[key];
      options.classes.push(`alert--${position}`);
    });

    $clone.data('options', options);

    $clone.find('.alert__content').append(options.content).append($btnContainer);

    // Add the alert to the DOM
    $clone.addClass(options.classes.join(' ')).appendTo(options.$container);

    // Bind close event on the close button
    $clone.find('.alert__close').on(
      'click',
      function () {
        this.close($clone);
      }.bind(this)
    );

    TweenLite.set($clone, {
      y: options.position.y === 'top' ? '-101%' : '101%',
    });

    // Animate it
    TweenLite.to($clone, 0.6, {
      y: '0%',
      ease: Expo.easeOut,
    });

    // Bind accept click
    $accept.on(
      'click',
      function () {
        this.close($clone);
        if ('callback' in options && typeof options.callback === 'function') {
          options.callback();
        }
      }.bind(this)
    );

    // Bind decline click
    $decline.on(
      'click',
      function () {
        this.close($clone);
      }.bind(this)
    );

    return false;
  };

  /**
   * Alias to open a new confirmation alert
   *
   * @param  {String}   content  The message of the confirmation
   * @param  {Function} callback The callback to execute when confirmation is confirmed
   * @return {Boolean}           Return the private function, see @Alert.prototype._confirm
   */
  Alert.prototype.confirm = function confirm(content, callback, labelConfirm, labelDecline) {
    const options = {
      content,
      callback,
      labelConfirm: typeof labelConfirm === 'string' ? labelConfirm : 'OK',
      labelDecline: typeof labelDecline === 'string' ? labelDecline : undefined,
    };

    return this._confirm(options);
  };

  /* ===========================================================================
   * Initialize the alert info globally
   * ======================================================================== */
  window.addEventListener('load', function () {
    const alert = new Alert();
    const alertElements = document.querySelectorAll('.js-alert');

    for (let i = 0; i < alertElements.length; i += 1) {
      const alertElement = alertElements[i];
      const content = alertElement.querySelector('.js-alert-content');

      if (!content) {
        /* eslint-disable no-continue */
        continue;
      }

      if (alertElement.classList.contains('js-alert-error')) {
        alert.error(content.innerHTML);
      } else if (alertElement.classList.contains('js-alert-success')) {
        alert.success(content.innerHTML, 5);
      } else if (alertElement.classList.contains('js-alert-warning')) {
        alert.warning(content.innerHTML);
      } else {
        alert.message(content.innerHTML, 5);
      }
    }

    // Init a new instance
    App.components.alert = alert;
  });
})(App, jQuery);
